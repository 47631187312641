import React from 'react'
import Logo from './Pages/Img/Logos.png'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='foot'>
                <br/>
             
                <h1> Mask Multispeciality Hospital  </h1>
                <h1 style={{marginTop:'-15px'}}>(A unit of LSH Hospital Pvt Ltd ) </h1>
                <p>We are providing advanced emergency services. We have well-equipped emergency and trauma center with facilities.
                </p>
                <p>Ph: 0522 3505600, 0522 3505601 </p>
            </div>

            <div className='foot'>
                <h1>FOLLOW US
                </h1>
                <br />
                <hr />

             <p>Web: www.maskhospitallucknow.com</p>
                <a href='' ><i class="fa fa-facebook-f"></i> Facebook</a><br />
                <a href=''> <i class="fa fa-twitter"></i> Twitter</a><br />
                <a href=''><i class="fa fa-youtube-play"></i> YouTube</a><br />
                <a href=''><i class="fa fa-instagram"></i> Instagram</a><br />
            </div>




            <div className='foot'>
                <h1>QUCIK LINKS
                </h1>
                
                <hr />
              <p> E-mail: corpmaskhospital2021@gmail.com </p>
                <p>Near Awadh Bus Stand, C.P. 5, Vikrant Khand, Gomti Nagar, Faizabad Road, Lucknow 226010</p>
                <a href='/'>Home</a><br />
                <a href='about'>About</a><br />
                <a href='contact'>Contact</a><br />
                <a href='Appointment'>Appointment</a><br />
                <a href='Gallery'>Gallery</a>

            </div>

        </div>
    )
}

export default Footer