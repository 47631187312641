import React from 'react'
import Footer from '../Footer'
import ortho from "./Img/onco.png";

const Obsgyno = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>


                <center><h1>Home / Gynecology  </h1></center>
                <br />

                <h2>Obstetrics involves care during pregnancy, childbirth, and the postpartum period. Gynecology deals with reproductive health and the functions and diseases specific to women and girls. </h2>
                <li>Physicians who focus on gynecology do not deliver babies or treat pregnant women.</li>
                <li>They conduct cancer screenings, treat urinary tract issues, and more. Physicians who focus on obstetrics do not treat health issues outside pregnancy.</li>
                <li>Seizures or episodes of shaking and apparent loss of consciousness (nonepileptic seizures)</li>
                <li>According to recent research, rates for burnout among obstetrician-gynecologists (Ob-Gyns) range somewhere between 40 and 75 percent, placing them in the top range of all medical specialties.</li>



            </div>

            <Footer />


        </>
    )
}

export default Obsgyno
