// Assuming this code is in a file named Contact.js

import React, { useState } from 'react';
import Footer from '../Footer';

export const Contact = () => {
  const [inpval, setINP] = useState({
    name: "",

    mobile: "",
    address: "",
    email: "",
    detail: ""
  });

  const setdata = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value
      }
    });
  }

  const addinpdata = async (e) => {
    e.preventDefault();

    const { name, contact, mobile, address, email, detail } = inpval

    if (name === "") {
      alert("Name is required");
      return;
    }



    if (mobile === "") {
      alert("Mobile is required");
      return;
    }

    if (address === "") {
      alert("Address is required");
      return;
    }

    if (email === "") {
      alert("Email is required");
      return;
    }

    if (detail === "") {
      alert("Details are required");
      return;
    }

    const res = await fetch("/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name, contact, mobile, address, email, detail
      })
    });

    const data = await res.json();
    console.log(data);

    if (!data || res.status === 404) {
      alert("Error saving data");
    } else {
      alert("Data Saved");
    }
  }

  return (
    <>

    <center><h1>Home / Contact</h1></center>
      <div className='contss'>
        <div className='container-fluid'>
          <div className='row conten'>
            <div className='col-sm-12'>
              <form >
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" className="form-control" value={inpval.name} onChange={setdata} name="name" placeholder="Enter name" />
                </div>

                <div className="form-group">
                  <label htmlFor="mobile">Mobile</label>
                  <input type="text" className="form-control" value={inpval.mobile} onChange={setdata} name="mobile" placeholder="Mobile Number" />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input type="text" className="form-control" value={inpval.address} onChange={setdata} name="address" placeholder="Address" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" value={inpval.email} onChange={setdata} name="email" aria-describedby="emailHelp" placeholder="Enter email" />
                </div>
                <div className="form-group form-check">
                  <label htmlFor="detail">Details</label>
                  <input type="text" className="form-control" value={inpval.detail} onChange={setdata} name="detail" placeholder="Detail" />
                </div>
                <button type="submit" onClick={addinpdata} className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>




        <div className='contss2'>

          <p>Ph: 0522 3505600, 0522 3505601</p>
          <p>E-mail: corpmaskhospital2021@gmail.com</p>
          <p>Web: www.maskhospitallucknow.com</p>
          <p>Near Awadh Bus Stand, C.P. 5, Vikrant Khand, Gomti Nagar, Faizabad Road, Lucknow</p>
        </div>





      </div>
      <Footer />
    </>
  );
}

export default Contact;
