import React from 'react'
import Footer from '../Footer'

const Trauma = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>


                <center><h1><u>Home / Trauma</u> </h1></center>

                <h2>Going through very stressful, frightening or distressing events is sometimes called trauma. When we talk about emotional or psychological trauma, we might mean: situations or events we find traumatic.</h2>
                <li>Acute trauma results from a single incident.
                </li>
                <li>Chronic trauma is repeated and prolonged such as domestic violence or abuse.</li>

                <li>Complex trauma is exposure to varied and multiple traumatic events, often of an invasive, interpersonal nature.
                </li>

            </div>

            <Footer />
        </>
    )
}

export default Trauma