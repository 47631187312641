import React from 'react'

const Doctor = () => {
    return (
        <div>
            <br />
            <center><h1>OUR DOCTOR</h1></center>
            <br />
            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Salauddin Khan</h1>
                    <h2>MBBS MD</h2>
                    <h2>Chest Medicine</h2>
                    <p> 10AM - 1PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. DP Singh</h1>
                    <h2>MD</h2>
                    <h2>Internal Medicine</h2>
                    <p>12PM - 6AM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. S A.Siddiqui</h1>
                    <h2>MD</h2>
                    <h2>Dermalogy</h2>

                    <p>10AM - 2PM</p>
                </div>

            </div>


            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr.Imitiyaz</h1>
                    <h2>MD</h2>
                    <h2>Surgeon</h2>
                    <p> 23PM - 9 AM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. V.K.Goel</h1>
                    <h2>MBBS,M.S</h2>
                    <h2>Genral Surgeon</h2>
                    <p>10AM - 12PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. P.C Tiwari</h1>
                    <h2>MBBS</h2>
                    <h2>Gernal Surgeon</h2>

                    <p>04AM - 18PM</p>
                </div>

            </div>


            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Subraharsh Singh</h1>
                    <h2>M.S</h2>
                    <h2>Onco Surgery</h2>
                    <p> 19PM - 21PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Hatif</h1>
                    <h2>Surgeon</h2>
                    <h2>Orthopadadic</h2>
                    <p>19PM - 22PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Naved</h1>
                    <h2>MBBS,MS</h2>
                    <h2>Ortho Surgery</h2>

                    <p>15PM - 18PM</p>
                </div>

            </div>

            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. A. Shaarma</h1>
                    <h2>MBBS,MD</h2>
                    <h2>Nephrology</h2>
                    <p> 19PM - 22PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. H. Gupta</h1>
                    <h2>D.M.</h2>
                    <h2>Cardiology</h2>
                    <p>19PM - 21PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Naushad</h1>
                    <h2>Cardiology</h2>
                    <h2>Cardiology</h2>

                    <p>14:30 PM - 15:30PM </p>
                </div>

            </div>

            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Himanshu</h1>
                    <h2>B.D.S. , M.D.S.</h2>
                    <h2>ORO & MAXO FACIAL</h2>
                    <p> 19PM - 20PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Anjana Jain</h1>
                    <h2>MBBS</h2>
                    <h2>OBS & Gynae</h2>
                    <p>17PM - 20PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Renu Garg</h1>
                    <h2>M.D</h2>
                    <h2>OBS & Gynae</h2>
                    <p>17PM - 20PM</p>
                </div>

            </div>


            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Noopur</h1>
                    <h2>.....</h2>
                    <h2>OBS & Gynae</h2>
                    <p> 18PM - 19PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Tanvi Meha</h1>
                    <h2>MBBS , M.D</h2>
                    <h2>Pediatric</h2>
                    <p>18PM - 19PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Saima Azim</h1>
                    <h2>....</h2>
                    <h2>ENT</h2>
                    <p>17PM - 18PM</p>
                </div>

            </div>




            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Haseeb Khan</h1>
                    <h2>MBBS , M.D</h2>
                    <h2>Neuropsychiatrist</h2>
                    <p> 17PM - 19PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Faran Ahmad</h1>
                    <h2>....</h2>
                    <h2>Neurosurgery</h2>
                    <p>13PM - 15PM</p>
                </div>

                <div className='ourdoctor'>
                    <h1>Dr.Mohammad Ziya</h1>
                    <h2>MBBS MS  </h2>
                    <h2>ENT</h2>

                </div>


            </div>

            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Gyan  Prakash</h1>
                    <h2>General Surgeon </h2>
                    <h2>Laproscopy</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr. A Kumar</h1>
                    <h2>....</h2>
                    <h2>Plastic Surgeon</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr.Manu Rastogi</h1>
                    <h2>Mcmch </h2>
                    <h2>ENT</h2>

                </div>


            </div>

            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Vivek  Singh</h1>
                    <h2>MBBS MD   </h2>
                    <h2>Anesthesia</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Kuldeep Singh</h1>
                    <h2>....</h2>
                    <h2>MD DM</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr.Sudhakar Panday</h1>
                    <h2>MBBS MD DM  </h2>
                    <h2></h2>

                </div>


            </div>

            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Vibhor  Mahendru</h1>
                    <h2>MBBS MD DNB   </h2>
                    <h2>onco</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Sandeep Verma</h1>
                    <h2>MS. MCH </h2>
                    <h2>Gastrosurgeon</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr.Dheeraj Singh</h1>
                    <h2> MD DM  </h2>
                    <h2>Cardiologist</h2>

                </div>

            </div>




            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Saytam  Shrivastava</h1>
                    <h2>MBBS MS MCH    </h2>
                    <h2>UROLOGIST</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Siddhart </h1>
                    <h2>MS.  </h2>
                    <h2>Orthopedic Surgeon</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr.Atit Tiwari</h1>
                    <h2> MBBS MS ORTHO  FIASM  </h2>
                    <h2></h2>

                </div>

            </div>


            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Rohit  Ailani</h1>
                    <h2>MBBS MS DNB     </h2>
                    <h2>Orthosurgeon</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Surjeet Singh </h1>
                    <h2>MS. Mch  </h2>
                    <h2>NeuroSurgeon</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr.Akanksha  </h1>
                    <h2> MBBS MD  </h2>
                    <h2>Obstetric gynae</h2>

                </div>

            </div>

            <div className='doctor'>

                <div className='ourdoctor'>
                    <h1>Dr. Namrata  </h1>
                    <h2> MS      </h2>
                    <h2>Gynae</h2>

                </div>

                <div className='ourdoctor'>
                    <h1>Dr. Sawood  </h1>
                    <h2>MS </h2>
                    <h2>Skin</h2>

                </div>



            </div>



        </div>
    )
}

export default Doctor