import React from 'react'
import Footer from '../Footer'

const Dermatology = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>

                <center><h1><u> Home / Dermatology </u> </h1></center>

                <h2>Dermatology is the branch of medicine dealing with the skin. It is a speciality with both medical and surgical aspects. A dermatologist is a specialist medical doctor who manages diseases related to skin, hair, nails, and some cosmetic problems.</h2>
                <li>A dermatologist is a medical doctor who specializes in conditions that affect the skin, hair, and nails. Whether it's rashes, wrinkles, psoriasis, or melanoma, no one understands your skin, hair, and nails better than a board-certified dermatologist.
                </li>
                <li>Skin diseases are conditions that affect your skin. These diseases may cause rashes, inflammation, itchiness or other skin changes. Some skin conditions may be genetic, while lifestyle factors may cause others. Skin disease treatment may include medications, creams or ointments, or lifestyle changes.</li>

                <li>From cosmetic treatments to cancer screenings, dermatologists work to keep you looking and feeling your best. Your skin does a lot to keep you healthy and deserves proper care. Its appearance reflects both your internal health and your inner beauty.
                </li>

            </div>

            <Footer />
        </>
    )
}

export default Dermatology