import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Banner1 from "./Pages/Img/banner.png";
import Banner2 from "./Pages/Img/Banner1.jpg";
import Banner3 from "./Pages/Img/banner4.png";

import Video from '../Video/Vid.mp4';

const Header = () => {

  const slider = (

    <AwesomeSlider className='awesome-slider'>

      <div data-src={Banner1} />
      <div data-src={Banner2} />
      <div data-src={Banner3} />

    </AwesomeSlider>

  );

  return slider;


}

export default Header;
