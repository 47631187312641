import React from 'react'
import ortho from "./Img/onco.png";
import Footer from '../Footer';

const Oncology = () => {
  return (
    <>
            <div className='departs'>
                <center><h1>Home / Oncology </h1></center>

                <h2>Oncology — the study and treatment of cancer — takes its name from the Greek word “oncos,” which means swelling and refers to the way Greek physicians described how tumors grew.</h2>
                <li>Benign: These tumors are not cancerous. They do not invade nearby tissue or spread to other parts of the body. ...</li>
                <li>Premalignant: In these tumors, the cells are not yet cancerous, but they can potentially become malignant.</li>
                <li>Malignant: Malignant tumors are cancerous.</li>
                

                <img src={ortho} />
            </div>


            <Footer />
        </>
  )
}

export default Oncology