import React from 'react'

const Question = () => {
  return (
    <div className='features'>

      <div className='fects2'>
        <h1>Emergency services</h1>
        <p>We are providing advanced emergency services We have well-equipped emergency and trauma center with facilities'</p>
      </div>
      <div className='fects2'>
        <h1>Qualified doctors</h1>
        <p>Our team of pathologists microbiologists and clinical laboratory scientists are always ready to help you with your laboratory needs</p>
      </div>
      <div className='fects2'>
        <h1>Outdoors checkup</h1>
        <p>Our doctors are always ready for outdoor checkup in an emergency we have different types of charges as per checkup'</p>
      </div>
      <div className='fects2'>
        <h1>Hours services</h1>
        <p>Our clinic provides extensive medical support and healthcare service 24/7'</p>
      </div>



    </div>
  )
}

export default Question