import React from 'react'



const Section = () => {
  return (


    <div className='section'>

      <h1 >About of MaskHospital Lucknow </h1>
      <p>"Mask hospital is a multispeciality hospital and has tertiary care and referral centre well equipped especially in the field of chest medicine and critical care department with state of art care at affordable cost. It is equipped with ICU, CCU, NICU, POCU, EMERGENCY, Dialysis unit, OT, Labour room etc."
      </p>

      <p>Mask Hospital in Lucknow City, Lucknow is a top player in the category Hospitals in the Lucknow. This well-known establishment acts as a one-stop destination servicing customers both local and from other parts of Lucknow. Over the course of its journey, this business has established a firm foothold in it's industry. The belief that customer satisfaction is as important as their products and services, have helped this establishment garner a vast base of customers, which continues to grow by the day. This business employs individuals that are dedicated towards their respective roles and put in a lot of effort to achieve the common vision and larger goals of the company. In the near future, this business aims to expand its line of products and services and cater to a larger client base. In Lucknow, this establishment occupies a prominent location in Lucknow City. It is an effortless task in commuting to this establishment as there are various modes of transport readily available. It is at Faizabad Road, Near Vikrant Khand 3, which makes it easy for first-time visitors in locating this establishment. It is known to provide top service in the following categories: Hospitals, Private Hospitals, Pmjay Scheme Hospitals</p>
      <br />
      <h1>Our Mission</h1>
      <br />
      <h5>We are providing advanced emergency services. We have well-equipped emergency and trauma center with facilities.
      </h5>



      <div className="btn ">

        <a href="Tel: 05223505601"> <button > Call</button></a>
        <a href=""><button>Read More</button></a>
        <a href=""><button> Appointment</button></a>


      </div>


    </div>



  )
}

export default Section