import "./App.css";

import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";

import Layout from "./components/Layout";
import Appointment from "./components/appointment";
import Gallery from './components/Gallery';
import Orthopadic from "./components/Pages/Orthopadic";
import Oncology from "./components/Pages/Oncology";
import Neurology from "./components/Pages/Neurology";
import Obsgyno from "./components/Pages/Obsgyno";
import Dentistry from "./components/Pages/Dentistry";
import Dialysis from "./components/Pages/Dialysis";
import Gastro from "./components/Pages/Gastro";



import Laboratory from "./components/Pages/Laboratory";
import Trauma from "./components/Pages/Trauma";
import Critical from "./components/Pages/Critical";
import Ambulance from "./components/Pages/Ambulance";
import Dermatology from "./components/Pages/Dermatology";
import Nephrology from "./components/Pages/Nephrology";
import Broncoscopy from "./components/Pages/Broncoscopy";
import Pediatric from "./components/Pages/Pediatric";



function App() {
  return (
    <>
      <Router>

        <NavBar />


        <Routes>

          <Route path="/" element={<Layout />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Appointment" element={<Appointment />} />
          <Route path="/Gallery" element={<Gallery />} />


          <Route path="/Orthopadic" element={<Orthopadic />} />
          <Route path="/Oncology" element={<Oncology />} />
          <Route path="/Neurology" element={<Neurology />} />
          <Route path="/Obsgyno" element={<Obsgyno />} />
          <Route path="/Dentistry" element={<Dentistry />} />
          <Route path="/Dialysis" element={<Dialysis />} />
          <Route path="/Gastro" element={<Gastro />} />


          <Route path="/Laboratory" element={<Laboratory />} />
          <Route path="/Trauma" element={<Trauma />} />
          <Route path="/Critical" element={<Critical />} />
          <Route path="/Ambulance" element={<Ambulance />} />
          <Route path="/Dermatology" element={<Dermatology />} />
          <Route path="/Nephrology" element={<Nephrology />} />
          <Route path="/Broncoscopy" element={<Broncoscopy />} />
          <Route path="/Pediatric" element={<Pediatric />} />



        </Routes>

        {/* <Layout /> */}

      </Router>

    </>

  );
  
}

export default App;
