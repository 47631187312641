import React from 'react';
import Video from '../Video/Vid.mp4';

const Videos = () => {
  return (
    <div>
      <video autoPlay loop muted style={{ width: '100%', height: '400px' }}>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default Videos;
