import React from 'react'

const Faqs = () => {
    return (
        <div className='faqqs'>


            <center><h2><b>Frequently Asked Questions</b></h2></center>


            <h1> 1. What is the average consulting fee at Mask Hospital in Lucknow?</h1>
            <p>For the average consulting fees at Mask Hospital, it is advisable to get in touch with them during their working hours from Monday: Open 24 Hrs, Tuesday: Open 24 Hrs, Wednesday: Open 24 Hrs, thu:- Open 24 Hrs, Friday- Open 24 Hrs, Saturday:- Open 24 Hrs, Sunday: Open 24 Hrs.</p>

            <h1>2. Is Mask Hospital clean and hygienic?</h1>
            <p>Almost all hospitals maintain high-level cleanliness to keep patients safe from any infection. For more information on the cleanliness maintained at Mask Hospital, you can check the customer ratings and reviews or visit the hospital in person.</p>

            <h1>3. Do hospitals provide ambulance services?</h1>
            <p>Yes, most hospitals provide ambulance services for transporting patients to the hospital and back. However, it is recommended to check with Mask Hospital for further information on this</p>


            <h1>4. Can I visit a patient any time of the day in a hospital?</h1>
            <p>No, there are fixed visiting hours at hospitals. You can contact the Mask Hospital for visiting hours.</p>


        </div>
    )
}

export default Faqs