import React from 'react'
import Footer from '../Footer'

const Ambulance = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>

                <center><h1><u> Home / Ambulance </u> </h1></center>

                <h2>With a 24-hour operational setup, the ambulance service is available round the clock, ready to provide immediate assistance. </h2>
                <li>The hospital's efficient dispatch system ensures that ambulances reach the location swiftly, minimizing the waiting time for patients in need.

                </li>
                <li>An ambulance is a medically equipped vehicle which transports patients to treatment facilities, such as hospitals. Typically, out-of-hospital medical care is provided to the patient during the transport. Ambulances are used to respond to medical emergencies by emergency medical services (EMS).</li>

               

            </div>

            <Footer />
        </>
    )
}

export default Ambulance