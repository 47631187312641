import React from "react";
import Footer from "../Footer";
import Glob from './Img/global.jpg';

export const Blog = () => {
  return (
    <>
      <div className="blog">
        
        <img src={Glob} />
        <p>The React. js framework is an open-source JavaScript framework and library developed by Facebook. It's used for building interactive user interfaces and web applications quickly and efficiently with significantly less code than you would with vanilla JavaScript.</p>

        <p>React is a library for building composable user interfaces. It encourages the creation of reusable UI components, which present data that changes over time. Lots of people use React as the V in MVC. React abstracts away the DOM from you, offering a simpler programming model and better performance.</p>

        <p>Node. js is an open-source, cross-platform JavaScript runtime environment and library for running web applications outside the client's browser. Ryan Dahl developed it in 2009, and its latest iteration, version 15.14, was released in April 2021.</p>





      </div>


      <Footer />

    </>
  );
};
