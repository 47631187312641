import React from 'react'
import chart from "../components/Pages/Img/plane.png"


const Content = () => {
    return (
        <div className='contents'>
            <div className='texts'>

                <h1>The Networking Department
                </h1>
                <h2>Sub: Empanelling our hospital in the list of your Esteemed Organizatio</h2>

                <p><b>Mask Hospital (A unit of LSH Hospital Pvt Ltd )</b>
                    This multi-speciality Medical Surgical Center is started functioning in 2021,catering to every requirement/
                    safety of the patient in the mosthy gienicand  professional conditions. <b>Mask Hospital (A unit of LSH Hospital Pvt Ltd )</b> is private full-fledge Multi-Specialty with 100 + bed  </p>

                <p>  Situated inheartof Lucknow, CP-5, Faizabad road, Vikrant Khand, Gomti Nagar. The hospital is registered with CMO Lucknow with Registration No. RMEE2123751 and Run By  Dr. Salauddin MBBS, MD, Pulmonary Medicine, JNMCH, AMU ● Ex Senior Registrar, Max Smart Hospital , Saket, New Delhi ● Ex Senior Registrar, Sir Ganga Ram Hospital, New Delhi ● Associate Consultant, Pulmonary & Critical Care, Medanta Hospital, Lucknow
                </p>

                <p><b>The facilities available include 100+ functional beds, fully equipped modular minor & major Operation Theatres, Labour Room, Nursery, ICU, ICCU, along with Dialysis unit, 24 hours Clinical Pathology Lab, E.C.G., Digital X-Ray, USG, TMT, Echo, High frequency C-Arm (Image Intensifier), Ventilators, Cardiac Monitors & Defibrillators, CT Scan and MRI, facilities are also arrange by the hospital. The hospital provides 24 x 7 Emergency and Ambulance services. Medical amenities available are General & Gastrointestinal Surgery, Ano-Rectal Surgery, Internal Medicine, Trauma, Gynae & Obst., Orthopedic Surgery, ENT, Ophthalmology, Endocrinology, Urology, Neuro-Surgery, Neuro Medicine, Dermatologist,</b></p>

            </div>

            <div className='sergion'>
                <h1>Psychiatrist, Dental Surgery, Pediatrics & Pediatrics Surgery, Physiotherapy, & others.
                </h1>
                <p>Further details about our facilities,staff and charges are enumerated ahead for your reference. I am sure that the facilities offered byMASK HOSPITAL(AUnit of LSH Hospital Pvt. Ltd.) will meet with your requirements and I look forward to receive  your complete support We hope you will give us an opportunity to serve your personnel to the best
                    of .</p>
                <p>Our ability by considering our organization for empanelment on your esteemed panel of nursing homes. For your kind perusal and necessary action for empanelment in case of any further queries please feel free to contact Mr. Ali (TPA Head)also for any assistance(Mobile:8115544244)</p>

                <p>Assuring you of excellent services at alltimes,we remain.</p>

                <p>Thanking you,<br />
                    yours sincerely For MASK HOSPITAL (A Unit of LSH Hospital Pvt. Ltd.)</p>
                <h1>OUR DECLARED POLICY:</h1>
                <p>High Standard Treatment is our top priority.</p>
                <p>Cost of Treatment is always competitive and reduced for ordinary people without changing the standard of treatment. In short, excellent treatment without considering payment mode.

                </p>
                <h2>OUR AIMS & OBJECTIVES:</h2>
                <p>If surgery has to be undertaken, the infection rate has to be kept extremely low as in the western world and for this no efforts have been spared and the goal has been achieved to a large extent. This is borne by the fact that several surgeons.</p>
                <p>Prompt and immediate treatment in case of emergencies has been our hallmark. Centre is kept under cover postgraduate resident doctors round the clock and the senior consultants are readily available on call.</p>
                <p>The standard of all departments, e.g. Laboratory, Radiology, Ultrasonography, Physiotherapy and has also been maintained at a high standard. Our nurses and their work are of high quality. They are courteous and render all the services with a smile.</p>

                <p>Paramedical staff and other staff of the center are constantly briefed about cleanliness, co-operative behaviors with the patients and above all courtesy of the highest standard.</p>
                <p>Benefits of referring patients to MASK HOSPITAL (A Unit of LSH Hospital Pvt. Ltd.):</p>

                <li>The patients will receive prompt and efficient service on arrival, whether in emergency or as a routine.</li>
                <li>They will be attended mostly by senior, highly qualified and experienced
                    consultants in different disciplines.
                </li>
                <li>They will receive very high standard of services under one roof, viz. doctors, laboratory, X-ray, ultrasound, casualty, physiotherapy, and catering</li>
                <li>Besides excellent treatment, the cost of treatment will be constantly kept low without sacrificing the standard of treatment</li>
                <li>Besides medical services, our ancillary services are also of high standard. We have trained highly experienced physiotherapist, laboratory technician and other staffs.</li>
                <li>Full medical checkup for all patients in general and executives in particular
                    is available and done within minimum time, very reasonable cost and high standard reports by our specialists.
                </li>
                <li>Our Nursing Home is applied for 17 (II) exemptions from the Income Tax
                    Department, Govt. of India, which will help the Executives from dual taxation.
                </li>
                <li>•	All types of surgeries are performed at MASK HOSPITAL (A Unit of LSH Hospital Pvt. Ltd.) by very renowned & efficient doctors.</li>
                <li>•	Nursery is well equipped and pre-mature & serious infants are looked after.</li>


            </div>


            <div className='dragws'>
                <h1>OUR DEPARTMENTS AND FACILITIES:</h1>
                <p>OPD Timings :</p>
                <p>(10 AM to 02 PM, 03PM TO 05 PM & 07 PM to 09 PM) </p>
                <p><b>INTERIORS</b></p>
                <p>The hospital is air-cooled with fine, impressive, sophisticated & soothing interiors.
                    It is equipped with all most recent safety systems & fire – alarms
                </p>
                <p><b>WAITING AREA</b></p>
                <p>Very comfortable & spacious waiting area sufficient to accommodate 60-80 persons at a time readily available in the hospital.</p>
                <p><b>RECEPTION & REGISTRATION</b></p>
                <p>Round the clock Reception & Registration facility is available with up-to-date information.</p>
                <p>LABORATORY, X-RAY, ULTRASOUND And PHARMACY SERVICES</p>
                <p>•	24 Hrs. 7 Days</p>
                <p><b>LABOUR ROOM</b></p>
                <p>•	Equipped with state-of-art labour table (motorized) with Monitor & CTG Machine.</p>
                <p><b>OT</b></p>
                <p>•	2 Most advanced Operation Theatres with well equipped with analyzers, monitors, And C- Arm Image Intensifier etc.</p>
                <p><b>SURGICAL/MEDICAL ICU</b></p>
                <p>•	ICU, with cardiac monitors, Defibrillator, Ventilator, Central Monitor with Centralized oxygen Supply.</p>
                <p><b>NICU</b></p>
                <p>•	Equipped Double Surface Phototherapy with warmer and multi-Para Monitor and Ventilator.</p>
                <p><b>CASUALTY</b></p>
                <p>•	24 HRS. Emergency attended by a senior resident round the clock.</p>
                <p><b>AMBULANCE</b></p>
                <p>•	An ambulance on–call facility available equipped with life saving resuscitation equipment round the clock for the city as well as long distance.</p>
                <p><b>GYNAECOLOGY</b></p>
                <p>Hysteroscopy	and	complete	equipment	for	lap	Gynae	work.	Best	facilities
                    available for Infertility (Male & Female), Ante-natal, Normal deliveries & Caesarian (L.S.C.S.) cases.
                </p>
                <p>KITCHEN FACILITIES ARE ALSO AVAILABLE IN PREMISES</p>


            </div>






            <div className='plane'>

                <h1>Hospital Plan</h1>
                <p>Emergency Rooms. 10 Beds </p>
                <p>ICU- 20Beds</p>
                <p>HDU- 10Beds</p>
                <p>Dialysis- 05 Beds</p>
                <p>NICU- 04 Beds</p>
                <p>Reception</p>
                <p>OPD Chambers - 05 </p>
                <p>Operation Theatre- 02 </p>
                <p>Labour Room,-01 Doctor </p>
                <p>Room</p>
                <p>Store Room, Toilets for Public & Staff Drinking </p>
                <p>water</p>
                <p>Nursing Station</p>
                <p>General Ward - 30 Beds (Male & Female) </p>
                <p>Private Rooms - 08 Beds</p>
                <p>S. Private Rooms -15 Beds </p>
                <p>Deluxe Room	- 05 Beds</p>
                <p>Pathology Lab, X-Ray, ECG Rooms. </p>
                <p>Oxygen Cylinder & Manifold </p>
                <p>Parking for Four & Two Wheelers</p>


            </div>




            <div className='important'>
                <h1>IMPORTANT HOSPITAL DETAILS</h1>
                <img src={chart} />


                <h1>HOSPITAL CHARGES</h1>

                <table>
                    <thead>
                        <tr>
                            <th>Type of Service</th>
                            <th>Charges (Per Day/Per Hour)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Deluxe/Suit Room</td>
                            <td>Rs. 7000/-</td>
                        </tr>
                        <tr>
                            <td>Private Room</td>
                            <td>Rs. 5000/-</td>
                        </tr>
                        <tr>
                            <td>Semi Private Room</td>
                            <td>Rs. 3000/-</td>
                        </tr>
                        <tr>
                            <td>General Ward Bed</td>
                            <td>Rs. 2000/-</td>
                        </tr>
                        <tr>
                            <td>ICU</td>
                            <td>Rs. 7000/-</td>
                        </tr>
                        <tr>
                            <td>NICU</td>
                            <td>Rs. 5000/-</td>
                        </tr>
                        <tr>
                            <td>O.T. Charges (1st hour)</td>
                            <td>Rs. 4000/-</td>
                        </tr>
                        <tr>
                            <td>O.T. Charges (Subsequent)</td>
                            <td>Rs. 2000/- Per Hour</td>
                        </tr>
                        <tr>
                            <td>Gas Charges (O2 + N2O)</td>
                            <td>Rs. 3000/- Per Hour</td>
                        </tr>
                        <tr>
                            <td>Oxygen (O2) Charges</td>
                            <td>Rs. 250/- Per Hour</td>
                        </tr>
                    </tbody>
                </table>

                <br /><br /><br /><br />
                <h1>EXTRA CHARGES</h1>


                <table>
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Charges</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Registration Charges</td>
                            <td>Rs. 500/-</td>
                        </tr>
                        <tr>
                            <td>Charges (Pvt. Room)</td>
                            <td>Rs. 500/- Per Day</td>
                        </tr>
                        <tr>
                            <td>Nursing Charges (Semi Pvt. Room)</td>
                            <td>Rs. 300/- Per Day</td>
                        </tr>
                        <tr>
                            <td>Nursing Charges (General ward)</td>
                            <td>Rs. 200/- Per Day</td>
                        </tr>
                        <tr>
                            <td>ECG Charges</td>
                            <td>Rs. 500/- per ECG</td>
                        </tr>
                        <tr>
                            <td>Nebulization Charges</td>
                            <td>Rs. 200/- per shot</td>
                        </tr>
                    </tbody>
                </table>

            </div>
















        </div>
    )
}

export default Content