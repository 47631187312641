import React from 'react'
import Footer from './Footer'
import gal1 from './Pages/Img/slider_img_1.jpg'
import gal2 from './Pages/Img/slider_img_2.jpg'
import gal3 from './Pages/Img/Banner1.jpg'
import gal4 from './Pages/Img/maskhospital__1634373806.png'
import gal5 from './Pages/Img/maskhospital__1634373823.png'
import gal6 from './Pages/Img/maskhospital__1634373843.png'
import gal7 from './Pages/Img/maskhospital__1634373864.png'
import gal8 from './Pages/Img/maskhospital__1634373883.png'

const Gallery = () => {
    return (
        <>

            <div>

                <center><h1>Home / Gallery</h1></center>

                <div className='gallery'>

                    <div className='gals'>
                        <img src={gal1} />

                    </div>

                    <div className='gals'>
                        <img src={gal2} />

                    </div>

                    <div className='gals'>
                        <img src={gal3} />

                    </div>

                    <div className='gals'>
                        <img src={gal4} />

                    </div>

                </div>


                <div className='gallery'>

                    <div className='gals'>
                        <img src={gal5} />

                    </div>

                    <div className='gals'>
                        <img src={gal6} />

                    </div>

                    <div className='gals'>
                        <img src={gal7} />

                    </div>

                    <div className='gals'>
                        <img src={gal8} />

                    </div>

                </div>



            </div>







            <Footer />

        </>
    )
}

export default Gallery