import React from 'react'
import JsonData from './data.json'
function JsonDataDisplay(){
	const DisplayData=JsonData.map(
		(e)=>{
			return(
				<tr>
					<td>{e.id}</td>
					<td>{e.name}</td>
					<td>{e.Clinic}</td>
					
				</tr>
			)
		}
	)

	return(
		<div className='datas'>
			<table class="table table-striped">
				<thead>
					<tr>
					<th>Sr.NO</th>
					<th>TREATMENT</th>
					<th>Clinic</th>
					
					</tr>
				</thead>
				<tbody>
				
					
					{DisplayData}
					
				</tbody>
			</table>
			
		</div>
	)
}

export default JsonDataDisplay;
