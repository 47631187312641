import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import Logo1 from './Pages/Img/pnglogo.png';

import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";




const DropdownMenu = ({ isOpen, handleClick }) => {

  return (

    <ul className={isOpen ? "dropdown-menu active" : "dropdown-menu"}>
      <li className="nav-item">
        <NavLink
          exact
          to="/Orthopadic"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Orthopedic<br/> Cancer Related Surgery
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/Oncology"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Oncology
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/Neurology"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         Neurology
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/Obsgyno"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Obs & Gynae
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          exact
          to="/Dentistry"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Dentistry
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          exact
          to="/Dialysis"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Dialysis
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          exact
          to="/Gastro"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Gastro Medicine
        </NavLink>
      </li>

{/* 
      <li className="nav-item">
        <li class="facility-item">24 Hrs. Emergency</li>
        <li class="facility-item">OrthopedicCancer Related Surgery</li>
        <li class="facility-item">Oncology</li>
        <li class="facility-item">Neurology & Internal Medicine</li>
        <li class="facility-item">24 Hrs. Ambulance Available</li>
        <li class="facility-item">Pharmacy (24x7)</li>
        <li class="facility-item">Dialysis</li>
        <li class="facility-item">Dentistry</li>
        <li class="facility-item">Surgery</li>
        <li class="facility-item">Obs & Gynae</li>
        <li class="facility-item">NICU</li>

        <li class="facility-item">Emergency</li>

      </li> */}







    </ul>
  );
};

const MediaDropdownMenu = ({ isOpen, handleClick }) => {
  return (
    <ul className={isOpen ? "dropdown-menu active" : "dropdown-menu"}>
      

      <li className="nav-item">
        <NavLink
          exact
          to="/Dermatology"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         Dermatology 
        </NavLink>
      </li>



      <li className="nav-item">
        <NavLink
          exact
          to="/Nephrology"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         Nephrology
        </NavLink>
      </li>



      <li className="nav-item">
        <NavLink
          exact
          to="/Broncoscopy"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         Bronchoscopy 
        </NavLink>
      </li>



      <li className="nav-item">
        <NavLink
          exact
          to="/Pediatric"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         Pediatric 
        </NavLink>
      </li>


      <li className="nav-item">
        <NavLink
          exact
          to="/Laboratory"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Laboratory (24x7)
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          exact
          to="/Trauma"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         Trauma
        </NavLink>
      </li>


      <li className="nav-item">
        <NavLink
          exact
          to="/Critical"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         ICU (Critical Care) 
        </NavLink>
      </li>


      <li className="nav-item">
        <NavLink
          exact
          to="/Ambulance"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
         24 Hrs. Ambulance
        </NavLink>
      </li>


    </ul>
  );
};

function NavBar() {
  const [click, setClick] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mediaDropdownOpen, setMediaDropdownOpen] = useState(false);

  const handleClick = () => {
    setClick(!click);
    setDropdownOpen(false);
    setMediaDropdownOpen(false);
  }

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const handleMediaDropdownClick = () => {
    setMediaDropdownOpen(!mediaDropdownOpen);
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <span>
              <img src={Logo1} style={{ width: "100%", height: "80px" }} />
            </span> 
            {/* <i className="fas fa-code"></i> */}
            <span className="icon"></span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item" onClick={handleDropdownClick}>
              <span className="nav-links">
              Department <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
              <DropdownMenu isOpen={dropdownOpen} handleClick={handleClick} />
            </li>

           



            <li className="nav-item" onClick={handleMediaDropdownClick}>
              <span className="nav-links">
              Facilities <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
              <MediaDropdownMenu
                isOpen={mediaDropdownOpen}
                handleClick={handleClick}
              />
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/Appointment"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Appointment
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/Gallery"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Gallery
              </NavLink>
            </li>
          </ul>

          <div className="nav-icon" onClick={handleClick}>

            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            ) : (

              <span className="icon">
                <HamburgetMenuOpen />{" "}
              </span>
            )}

          </div>

        </div>
      </nav>
    </>
  );
}

export default NavBar;
