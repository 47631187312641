import React from 'react'
import Footer from '../Footer'

const Critical = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>

                <center><h1><u> Home / Critical </u> </h1></center>

                <h2>Critical thinking empowers individuals to approach decision-making and problem-solving with clarity, logic, and a systematic approach. Consequently, this leads to more informed choices, innovative solutions, and better outcomes.</h2>
                <li>One of the aims of critical thinking is to improve the quality of our beliefs, judgments and decisions. What does this mean? It can mean different things, depending on which of these we're talking about. When we're talking about beliefs, the most obvious measure of quality is how likely they are to be true.
                </li>
                <li>Critical thinking is the intellectually disciplined process of actively and skillfully conceptualizing, applying, analyzing, synthesizing, and/or evaluating information gathered from, or generated by, observation, experience, reflection, reasoning, or communication, as a guide to belief and action.</li>

                <li>By employing critical thinking, individuals can evaluate information objectively, consider multiple perspectives, and analyze complex situations more effectively. It enables them to identify underlying assumptions, uncover biases, and assess the credibility and relevance of data and evidence.
                </li>

            </div>

            <Footer />
        </>
    )
}

export default Critical