import React from 'react'
import Footer from '../Footer'

const Dialysis = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>

                <center><h1>Home / Dialysis  </h1></center>
                <br />
                <h2>Dialysis is a procedure to remove waste products and excess fluid from the blood when the kidneys stop working properly. It often involves diverting blood to a machine to be cleaned.</h2>
               <h2> 3 types of dialysis?</h2>
               <li>In-center hemodialysis</li>
               <li>Home hemodialysis,</li>
               <li>Peritoneal dialysis</li>

            </div>

            <Footer />
        </>
    )
}

export default Dialysis