import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import sl1 from './Pages/Img/onco.png';
import sl2 from './Pages/Img/ortho.png';
import sl3 from './Pages/Img/internal.png';
import sl4 from './Pages/Img/slider_img_1.jpg';
import sl5 from './Pages/Img/banner.png';
import sl6 from './Pages/Img/banner2.png';
import sl7 from './Pages/Img/banner3.png';
import sl8 from './Pages/Img/banner4.png';

const Slider = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const slideItems = [
        <img src={sl1} alt="Slide 1" style={{ width: '300px', height: '300px' }} />,
        <img src={sl2} alt="Slide 2" style={{ width: '300px', height: '300px' }} />,
        <img src={sl3} alt="Slide 3" style={{ width: '300px', height: '300px' }} />,
        <img src={sl4} alt="Slide 4" style={{ width: '300px', height: '300px' }} />,
        <img src={sl5} alt="Slide 3" style={{ width: '300px', height: '300px' }} />,
        <img src={sl6} alt="Slide 4" style={{ width: '300px', height: '300px' }} />,
        <img src={sl7} alt="Slide 1" style={{ width: '300px', height: '300px' }} />,
        <img src={sl8} alt="Slide 2" style={{ width: '300px', height: '300px' }} />,
    ];

    return (
        <div style={{ textAlign: 'center' }}>
            <br />
            <center><h1>OUR DEPARTMENT</h1></center>
            <br />
            <Carousel
                responsive={responsive}
                showDots
                arrows
            >
                {slideItems}
            </Carousel>
        </div>
    );
}

export default Slider;
