import React from 'react'
import Footer from '../Footer'

const Pediatric = () => {
  return (
    <>
    <div className='departs' style={{ background: "none" }}>

        <center><h1><u> Home / Pediatric </u> </h1></center>

        <h2>Paediatricians are medical specialists who diagnose, treat and provide medical care for babies, children and teenagers.</h2>
        <li>Paediatricians treat your child's illnesses, as well as assess their physical, mental and behavioural development. Paediatricians work in the community as well as in public and private hospitals.
        </li>
        <li>Originally defined as age 18, raised to age 21 in the 1960s, the upper age limit for pediatric care was eliminated by the American Academy of Pediatrics in 2017. The rationale: Transition to adult care should be specific to patient needs, not an arbitrary number.</li>

       

    </div>

    <Footer />
</>
  )
}

export default Pediatric