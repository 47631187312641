import React from 'react'
import Footer from '../Footer'
import ortho from "./Img/onco.png";



const Dentistry = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>


                <center><h1>Home / Dentistry  </h1></center>
                <br />

                <h2>Dentistry, also known as dental medicine and oral medicine, is the branch of medicine focused on the teeth, gums, and mouth. </h2>
                <li>Dentists are healthcare professionals responsible for providing oral health guidance and instruction to patients and carrying out preventative and restorative care; they treat a variety of diseases affecting not only the teeth but the gums and the mouth as a whole.</li>
                <li>Dentistry is the diagnosis, treatment, and prevention of conditions, disorders, and diseases of the teeth, gums, mouth, and jaw. Often considered necessary for complete oral health, dentistry can have an impact on the health of your entire body.</li>
                <li>The term dentistry comes from dentist, which comes from French dentiste, which comes from the French and Latin words for tooth.</li>
                <li>A good dentist is essential for ensuring your teeth are healthy and functioning properly. A poorly treated tooth can lead to a host of other problems, including difficulty chewing, pain, and even the development of dental cavities.</li>

            </div>

            <Footer />

        </>

        
    )
}

export default Dentistry