import React from 'react'
import Footer from '../Footer'
import ortho from "./Img/ortho.png";


const Orthopadic = () => {
    return (

        <>
            <div className='departs'>
                <center><h1>Home / Orthopadic </h1></center>

                <h2>The main symptoms include:</h2>
                <li>persistent bone pain that gets worse over time and continues into the night.</li>
                <li>swelling and redness (inflammation) over a bone, which can make movement difficult if the affected bone is near a joint.</li>
                <li>a noticeable lump over a bone.</li>
                <li>a weak bone that breaks (fractures) more easily than normal.</li>

                <img src={ortho} />
            </div>


            <Footer />
        </>
    )
}

export default Orthopadic