import React from 'react'
import Footer from '../Footer'

const Laboratory = () => {
    return (
        <>
            <div className='departs' style={{background:"none"}}>


                <center><h1><u>Home / Laboratory</u> </h1></center>

                <h2>Labs provide students with various opportunities to learn and experiment, which plays a crucial role in the ongoing intellectual development of students at any academic level. </h2>
                <li>A laboratory is a building or a room where scientific experiments, analyses, and research are carried out. A laboratory in a school, college, or university is a room containing scientific equipment where students are taught science subjects such as chemistry.
                </li>
            </div>

            <Footer />
        </>
    )
}

export default Laboratory