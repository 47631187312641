import React from 'react'

const Depart = () => {
    return (

        <div>
           

            <div className='depart'>

                <div className='depart2'>
                    <h1>Orthopedic(Cancer  Surgery)</h1>
                    <p>Your surgeon will remove the entire tumor, as well as a small area of healthy cells around the tumor, to make sure the cancer is removed completely.</p>
                </div>

                <div className='depart2'>
                    <h1>Oncology</h1>
                    <p>An oncologist is a doctor who treats cancer and provides medical care for a person diagnosed with cancer. An oncologist may also be called a cancer specialist. </p>
                </div>

                <div className='depart2'>
                    <h1>Neurology & Internal Medicine</h1>
                    <p>Internal medicine physicians are specialists who apply scientific knowledge and clinical expertise to the diagnosis, treatment, and compassionate care of adults across the spectrum from health to complex illness.</p>
                </div>


                <div className='depart2'>
                    <h1>Dialysis</h1>
                    <p>Dialysis is a treatment for people whose kidneys are failing. When you have kidney failure, your kidneys don't filter blood the way they should. As a result, wastes and toxins build up in your bloodstream.</p>
                </div>

            </div>



            <div className='depart'>

                {/* <div className='depart2'>
                    <h1>Dentistry</h1>
                    <p>Dentists are the frontline of good oral health. They work with communities to prevent and treat dental/oral disease, correct dental irregularities and treat dental/facial injuries.</p>
                </div> */}

                <div className='depart2'>
                    <h1>Surgery</h1>
                    <p>Surgery is a medical specialty that uses manual and/or instrumental techniques to physically reach into a subject's body in order to investigate or treat pathological conditions such as a disease or injury, </p>
                </div>

                <div className='depart2'>
                    <h1>Obs & Gynae</h1>
                    <p>Obstetrics and gynecologist (also spelled as Obstetrics and Gynecology; abbreviated as Obs and Gynae, O&G, OB-GYN and OB/GYN) is the medical specialty that encompasses the two sub specialties of obstetrics and gynecology </p>
                </div>


                <div className='depart2'>
                    <h1>Dermatology</h1>
                    <p>Dermatologists diagnose and treat skin conditions. They also recognize symptoms that appear on your skin which may indicate problems inside your body, like organ disease or failure. Dermatologists often perform specialized diagnostic procedures related to skin conditions.</p>
                </div>

            </div>





            <div className='depart'>

                <div className='depart2'>
                    <h1>Bronchoscopy</h1>
                    <p>Bronchoscopy is a test to view the airways and diagnose lung disease. It may also be used during the treatment of some lung conditions.</p>
                </div>

                <div className='depart2'>
                    <h1>Psychiatry</h1>
                    <p>Psychiatry is the branch of medicine focused on the diagnosis, treatment and prevention of mental, emotional and behavioral disorders.</p>
                </div>

                <div className='depart2'>
                    <h1>Neuro Surgery</h1>
                    <p>It is the medical specialty concerned with the diagnosis and treatment of of patients with injury to, or diseases/disorders of the brain, spinal cord and spinal column, and peripheral nerves within all parts of the body. </p>
                </div>


                <div className='depart2'>
                    <h1>Pulmonary</h1>
                    <p>Your pulmonary arteries are blood vessels that carry oxygen-poor blood from the right side of your heart to your lungs. </p>
                </div>

            </div>




        </div>

    )
}

export default Depart