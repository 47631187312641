import React from 'react'

const Emergenct = () => {
    return (
        <div className='emergency'>

          <p>  In case of emergency call  0522 3505601</p>
            <a href="Appointment"><button>OR BOOK AN APPOINTMENT </button></a>
        </div>
    )
}

export default Emergenct