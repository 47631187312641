import React from 'react'
import Footer from '../Footer'
import ortho from "./Img/onco.png";

const Neurology = () => {
  return (
    <>
      <div className='departs'>


        <center><h1>Home / Neurology </h1></center>

        <h2>Physicians cannot cure many neurological disorders, but rehabilitation can assist in treating symptoms and the restoration of daily functioning.</h2>
        <li>Weakness or paralysis.</li>
        <li>Abnormal movement, such as tremors or difficulty walking..</li>
        <li>Seizures or episodes of shaking and apparent loss of consciousness (nonepileptic seizures)</li>


        <img src={ortho} />

      </div>

      <Footer />


    </>
    
  )
}

export default Neurology