import React from 'react'
import Footer from '../Footer'

const Gastro = () => {
  return (
    <>
    <div className='departs' style={{ background: "none" }}>

        <center><h1>Home / Gastro   </h1></center>
        <br />
        <h2>Gastroenteritis, also known as infectious diarrhea or simply as gastro, is an inflammation of the gastrointestinal tract including the stomach and intestine. Symptoms may include diarrhea, vomiting, and abdominal pain.</h2>
      
       <li>It's usually caused by a bacterial or viral tummy bug. It affects people of all ages, but is particularly common in young children. Most cases in children are caused by a virus called rotavirus. Cases in adults are usually caused by norovirus (the 'winter vomiting bug') or bacterial food poisoning</li>
       <li>Oral rehydration drinks, available from your pharmacist. Admission to hospital and intravenous fluid replacement, in severe cases. Antibiotics, if bacteria are the cause. Drugs to kill the parasites, if parasites are the cause.</li>
       <li>It spreads when a person comes in contact with the vomit or faeces (poo) of an infected person, including: person-to-person contact, for example shaking hands with someone who has been sick and has the virus on their hands. contaminated objects. contaminated food or drink.</li>

    </div>

    <Footer />
</>
  )
}

export default Gastro