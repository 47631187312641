import React from 'react'
import Footer from '../Footer'

const Nephrology = () => {
    return (
        <>
            <div className='departs' style={{ background: "none" }}>

                <center><h1><u> Home / Nephrology </u> </h1></center>

                <h2>Nephrology is the adult and pediatric study of the kidneys and its diseases.</h2>
                <li>The nephrologist deals with the diagnosis and management of kidney disease. The kidneys are vital for maintaining normal fluid and electrolyte balance in the body.
                </li>
                <li>A nephrologist diagnoses and treats kidney conditions and kidney failure. In addition, they also recognize how kidney conditions affect other parts of your body, including: Autoimmune diseases. Cardiovascular disease</li>

                <li>Sometimes called renal medicine, nephrology is a specialty within the internal medicine field related to kidney care. It is often connected with hypertension or high blood pressure. Nephrologists are medical professionals who diagnose, treat, and manage acute and chronic kidney problems and diseases.
                </li>

            </div>

            <Footer />
        </>
    )
}

export default Nephrology