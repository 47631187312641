import React from 'react'
import Header from './Header';
import Section from './Section';
import Chart from './Chart';
import JasonDataDisplay from './JsonDataDisplay';
import Footer from './Footer';
import Question from './Question';
import Doctor from './Doctor';
import Depart from './Depart';
import Slider from './Slider';
import Maps from './Maps';
import Emergenct from './Emergenct';
import Videos from './Videos';
import Faqs from './Faqs';
import Content from './Content';


const Layout = () => {
  return (

    <>

      <div className='mains'>
        
        <Header />
        <Section />
        <Content/>
        <Question />
    
        <Emergenct/>

        
        <Doctor />
        <Faqs/>
        <Slider/>
        <Depart />
        <br/>
        <Videos/>
        <br/>

        <Maps/>
        <Footer />


      </div>

    </>

  )
}

export default Layout