import React from 'react'
import Footer from '../Footer'

const Broncoscopy = () => {
  return (
    <>
    <div className='departs' style={{ background: "none" }}>

        <center><h1><u> Home / Broncoscopy </u> </h1></center>

        <h2>Bronchoscopy is a technique used to look at your air passages with a small camera that is located at the end of a flexible tube. </h2>
        <li>Bronchoscopy is a test to view the airways and diagnose lung disease. It may also be used during the treatment of some lung conditions. Bronchoscopy is a surgical technique for viewing the interior of the airways.
        </li>
        <li>During the test. Your doctor puts the bronchoscope into your mouth or down a nostril to your airways. This is a bit uncomfortable but it doesn't last long and shouldn't hurt because you have had the local anaesthetic. You can breathe normally.</li>

       

    </div>

    <Footer />
</>
  )
}

export default Broncoscopy